

import DxList from 'devextreme-vue/list';
import JobTitleService from '../services/jobtitle-service';
 

export default {
  name: "JobTitleTemplate",
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    const {
      nombre,
    } = this.templateData;
     
    return {
      id_puesto: null,
      nombre: null,
      dataSourceJobTitles: [
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 1o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 2o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 3o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 4o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 5o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 1o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 2o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 3o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 4o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },
        { id: 1, descripcion: 'Capitulo I - Normas Preliminares -  ARTÍCULO 5o.- La presente Ley es reglamentaria de las disposiciones de la Constitución Política de los Estados Unidos Mexicanos que se refieren a la preservación y restauración del equilibrio ecológico, así como a la protección al ambiente, en el territorio nacional y las zonas sobre las que la nación ejerce su soberanía y jurisdicción. Sus disposiciones son de orden público e interés social y tienen por objeto propiciar el desarrollo sustentable y establecer las bases para: ' },

      ],
      selectedJobTitles: [],
       
      detailInfo: `${nombre}`,
    };
  },
  created() {
    this.jobTitleService = new JobTitleService();
    console.log('created jobtitle template');
  },
  mounted() {
    
    console.log('mounted jobtitle template');
  },
};
